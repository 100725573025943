import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
          //  new AppMenuItem('Apps', '', 'fa fa-shapes', '/app/main/welcome'),
            new AppMenuItem('Home', '', 'fa fa-home', '/app/main/home'),
         //   new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa fa-chart-bar', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),


   
                    new AppMenuItem('OffTakers', 'Pages.OffTakers', 'fa fa-truck-loading', '/app/main/offTakers/offTakers'),
                new AppMenuItem('Prices', 'Pages.CropPrices', 'fa fa-tags', '/app/main/cropPrices/cropPrices'),
                new AppMenuItem('Costs', 'Pages.ProduceCosts', 'fa fa-ticket-alt', '/app/main/produceCosts/produceCosts'),

                new AppMenuItem('Sales', '', 'fa  fa-chart-line', '', [
                new AppMenuItem('Bids', 'Pages.ProduceBids', 'fa fa-gavel', '/app/main/produceBids/produceBids'),
                new AppMenuItem('Carts', 'Pages.ProduceCarts', 'fa fa-shopping-basket', '/app/main/produceCarts/produceCarts'),
                new AppMenuItem('Invoices', 'Pages.ProduceInvoices', 'fa fa-file-invoice', '/app/main/produceInvoices/produceInvoices')]),

                //new AppMenuItem('ProduceInvoiceDetails', 'Pages.ProduceInvoiceDetails', 'flaticon-more', '/app/main/produceInvoiceDetails/produceInvoiceDetails'),

                new AppMenuItem('Accounting', '', 'fa fa-coins', '', [
                new AppMenuItem('Farmer Accounts', 'Pages.FarmerDebtorBalances', 'fa fa-user-tag', '/app/main/farmerDebtorBalances/farmerDebtorBalances'),
                new AppMenuItem('Farmer Costs', 'Pages.FarmerProduceCosts', 'fa fa-ticket-alt', '/app/main/farmerProduceCosts/farmerProduceCosts'),
                new AppMenuItem('Farmer Settlements', 'Pages.ProduceCostSettlements', 'fa fa-columns', '/app/main/produceCostSettlements/produceCostSettlements')])
            ,


            new AppMenuItem('Inventory', '', 'fa fa-tasks', '', [
                new AppMenuItem('Warehouses', 'Pages.Warehouses', 'fa fa-warehouse', '/app/main/warehouses/warehouses'),
                new AppMenuItem('Produce', 'Pages.WarehouseProduces', 'fa fa-boxes', '/app/main/warehouseProduces/warehouseProduces'),
              //  new AppMenuItem('Inventory', 'Pages.WarehouseInputInventories', 'fa fa-clipboard-list', '/app/main/warehouseInputInventories/warehouseInputInventories'),

                new AppMenuItem('Receipts', 'Pages.ProduceReceipts', 'fa fa-boxes', '/app/main/produceReceipts/produceReceipts')
                //new AppMenuItem('InputDeliveryDetails', 'Pages.InputDeliveryDetails', 'flaticon-more', '/app/main/inputDeliveryDetails/inputDeliveryDetails'),
              //  new AppMenuItem('InputItemIssuances', 'Pages.InputItemIssuances', 'flaticon-more', '/app/main/inputItemIssuances/inputItemIssuances'),
               // new AppMenuItem('IssuanceDetails', 'Pages.IssuanceDetails', 'flaticon-more', '/app/main/issuanceDetails/issuanceDetails')
            ]),


            new AppMenuItem('Set Up', '', 'fa fa-cog', '', [
                new AppMenuItem('Agriculture', '', 'fa fa-ellipsis-h', '', [
                    new AppMenuItem('Crops', 'Pages.Crops', 'flaticon-more', '/app/main/crops/crops'),
                   // new AppMenuItem('CropPhases', 'Pages.CropPhases', 'flaticon-more', '/app/main/cropPhases/cropPhases'),
                   // new AppMenuItem('SoilTypes', 'Pages.SoilTypes', 'flaticon-more', '/app/main/soilTypes/soilTypes'),
                   // new AppMenuItem('EOP Templates', 'Pages.EOPTemplates', 'flaticon-more', '/app/main/eopTemplates/eopTemplates'),
                 //   new AppMenuItem('EOPTemplateDetails', 'Pages.EOPTemplateDetails', 'flaticon-more', '/app/main/eopTemplateDetails/eopTemplateDetails'),
                    new AppMenuItem('Farmers', 'Pages.Farmers', 'flaticon-more', '/app/main/farmers/farmers'),
                   // new AppMenuItem('Farms', 'Pages.Farms', 'flaticon-more', '/app/main/farms/farms'),
                   // new AppMenuItem('FarmCrops', 'Pages.FarmCrops', 'flaticon-more', '/app/main/farmCrops/farmCrops'),
                    //new AppMenuItem('FarmSoilTypes', 'Pages.FarmSoilTypes', 'flaticon-more', '/app/main/farmSoilTypes/farmSoilTypes')
                ]),

                new AppMenuItem('Locations', '', 'fa fa-ellipsis-h', '', [
                    new AppMenuItem('States', 'Pages.CountryStates', 'flaticon-more', '/app/main/countryStates/countryStates'),
                    new AppMenuItem('Local Govt Areas', 'Pages.StateLGAs', 'flaticon-more', '/app/main/stateLGAs/stateLGAs')]),



                new AppMenuItem('General', '', 'fa fa-ellipsis-h', '', [
                    new AppMenuItem('Measures', 'Pages.Measures', 'flaticon-more', '/app/main/measures/measures'),
                    new AppMenuItem('Brands', 'Pages.Brands', 'flaticon-more', '/app/main/brands/brands')]
                ),

                new AppMenuItem('Input', '', 'fa fa-ellipsis-h', '', [
                    new AppMenuItem('ItemTypes', 'Pages.ItemTypes', 'flaticon-more', '/app/main/itemTypes/itemTypes'),
                    new AppMenuItem('InputItems', 'Pages.InputItems', 'flaticon-more', '/app/main/inputItems/inputItems')])
            ]),
           
            

            
          
         
         //   new AppMenuItem('ExpectedProduceHarvests', 'Pages.ExpectedProduceHarvests', 'flaticon-more', '/app/main/expectedProduceHarvests/expectedProduceHarvests'),
            

             new AppMenuItem('Administration', '', 'fa fa-project-diagram', '', [
               // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
              //  new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
              //  new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ])
         //   new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
