import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
     
        ApiServiceProxies.ExpectedProduceHarvestsServiceProxy,        
        ApiServiceProxies.SupplierSettlementsServiceProxy,        
        ApiServiceProxies.SupplierAccountsServiceProxy,        
        ApiServiceProxies.FarmerDebtorBalancesServiceProxy,        
        ApiServiceProxies.ProduceCostSettlementsServiceProxy,        
        ApiServiceProxies.FarmerProduceCostsServiceProxy,        
        ApiServiceProxies.ProduceCostsServiceProxy,              
        ApiServiceProxies.ProduceCartsServiceProxy,        
        ApiServiceProxies.ProduceInvoiceDetailsServiceProxy,        
        ApiServiceProxies.ProduceInvoicesServiceProxy,        
        ApiServiceProxies.WarehouseProducesServiceProxy,        
        ApiServiceProxies.ProduceBidsServiceProxy,        
        ApiServiceProxies.ProduceReceiptsServiceProxy,        
        ApiServiceProxies.OffTakersServiceProxy,        
        ApiServiceProxies.CropPricesServiceProxy,        
        ApiServiceProxies.IssuanceDetailsServiceProxy,        
        ApiServiceProxies.InputItemIssuancesServiceProxy,        
        ApiServiceProxies.InputDeliveryDetailsServiceProxy,        
        ApiServiceProxies.InputDeliveriesServiceProxy,        
        ApiServiceProxies.PurchaseOrdersServiceProxy,        
        ApiServiceProxies.SupplierBidDetailsServiceProxy,        
        ApiServiceProxies.SupplierBidsServiceProxy,        
        ApiServiceProxies.RequestForBidDetailsServiceProxy,        
        ApiServiceProxies.RequestForBidsServiceProxy,        
        ApiServiceProxies.InputRequestDetailsServiceProxy,        
        ApiServiceProxies.InputItemRequestsServiceProxy,        
        ApiServiceProxies.SuppliersServiceProxy,        
        ApiServiceProxies.WarehouseInputInventoriesServiceProxy,        
        ApiServiceProxies.WarehousesServiceProxy,        
        ApiServiceProxies.EOPTemplateDetailsServiceProxy,        
        ApiServiceProxies.EOPTemplatesServiceProxy,        
        ApiServiceProxies.BrandsServiceProxy,        
        ApiServiceProxies.InputItemsServiceProxy,        
        ApiServiceProxies.ItemTypesServiceProxy,        
        ApiServiceProxies.MeasuresServiceProxy,        
        ApiServiceProxies.FarmSoilTypesServiceProxy,        
        ApiServiceProxies.SoilTypesServiceProxy,        
        ApiServiceProxies.FarmCropsServiceProxy,        
        ApiServiceProxies.FarmsServiceProxy,        
        ApiServiceProxies.StateLGAsServiceProxy,        
        ApiServiceProxies.CountryStatesServiceProxy,        
        ApiServiceProxies.FarmersServiceProxy,        
        ApiServiceProxies.CropPhasesServiceProxy,        
        ApiServiceProxies.CropsServiceProxy,        
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
